import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Route, Switch, Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { SocketContext, socket_io } from "./lib/socket_io_context";

// https://thecodebeast.com/configure-react-js-proxy-manually/
// https://create-react-app.dev/docs/proxying-api-requests-in-development/

import { AppTopbar } from "./AppTopbar";
// import AppTopbar from './AppTopbar';
// import { AppFooter } from './AppFooter';
import { AppMenu } from "./AppMenu";

import { AppConfig } from "./AppConfig";
// import AppConfig, { toggleConfigurator } from './AppConfig';
// import { AppConfig,  toggleConfigurator } from './AppConfig';
// JDG: NOPE ... : get func as method: AppConfig.toggleConfigurator()

import { Maintenance } from "./pages/Maintenance";

import ErrorBoundary from "./components/common/ErrorBoundary";
// import { TMC5dash } from './components/TMC5dash';
import { OverviewPage } from "./pages/Overview";
import { AdminPage } from "./pages/Admin";
import { StatusPage } from "./pages/Status";
// import { ReportsPage }      from './pages/Reports';
import { AnchorsPage } from "./pages/Anchors";
import { DashboardV3Page } from "./pages/DashboardV3Page";
import { DesignPage } from "./pages/DesignPage";
import { FloorplanV3Page } from "./pages/FloorplanV3Page";
import { AnchorV3DataPage } from "./pages/AnchorV3data";
import { PageAnchorDetail } from "./pages/PageAnchorDetail";
// import AnchorsPage          from './pages/Anchors';
import { AnchorHealthPage } from "./pages/AnchorHealth";
import { TokensPage } from "./pages/Tokens";
import { TokenV3DataPage } from "./pages/TokenV3data";
import { TableColors } from "./components/TableColors";
import { PageAnchorGroups } from "./pages/PageAnchorGroups";
import { TokenGroups } from "./pages/TokenGroups";
import { PageCdfuncs } from "./pages/PageCdfuncs";
import { PageConfigTypes } from "./pages/PageConfigTypes";
import { PageConfigMessages } from "./pages/PageConfigMessages";
import { EventsPage } from "./pages/Events";
import { TimepointsPage } from "./pages/Timepoints";
import { MapsPage } from "./pages/Maps";
import { MqttPage } from "./pages/MQTT";
import { MqttPubPage } from "./pages/MQTT-pub";
import { QMessagesPage } from "./pages/QMessages";
import { DashboardPage } from "./pages/Dashboard";
//
import { Dashboard } from "./components/Dashboard";
import { ButtonDemo } from "./components/ButtonDemo";
import { ChartDemo } from "./components/ChartDemo";
import { Documentation } from "./components/Documentation";
import { FileDemo } from "./components/FileDemo";
import { FloatLabelDemo } from "./components/FloatLabelDemo";
import { FormLayoutDemo } from "./components/FormLayoutDemo";
import { InputDemo } from "./components/InputDemo";
import { ListDemo } from "./components/ListDemo";
import { MenuDemo } from "./components/MenuDemo";
import { MessagesDemo } from "./components/MessagesDemo";
import { MiscDemo } from "./components/MiscDemo";
import { OverlayDemo } from "./components/OverlayDemo";
import { PanelDemo } from "./components/PanelDemo";
import { TableDemo } from "./components/TableDemo";
import { TreeDemo } from "./components/TreeDemo";
import { InvalidStateDemo } from "./components/InvalidStateDemo";

import { CrudPage } from "./pages/Crud";
import { EmptyPage } from "./pages/EmptyPage";
import { TimelineDemo } from "./pages/TimelineDemo";

// Icons =>        https://www.primefaces.org/primereact/icons/
// CSS =>          https://www.primefaces.org/primeflex/
// Componenets =>  https://www.primefaces.org/primereact/calendar/
//
import PrimeReact from "primereact/api";
//
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
// jdg
import "primereact/resources/themes/saga-blue/theme.css";

// import { CommonState } from './CommonState';
// const commonState = new CommonState();

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  // const [layoutColorMode, setLayoutColorMode] = useState('dim')
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(false);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

  PrimeReact.ripple = false;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  // const onMobileSubTopbarMenuClick = (event) => {
  const onMobileSubTopbarMenuClick = (event, type) => {
    mobileTopbarMenuClick = true;
    event.preventDefault();

    // JDG
    // console.log("# onMobileSubTopbarMenuClick with type = ", type);

    switch (type) {
      case "cal":
        // console.log("# onMobileSubTopbarMenuClick type=cal ..");
        //
        // const open_url = "api/sys-ping";
        // window.open(open_url);
        window.open("api/sys-ping");
        //
        break;
      case "cog":
        console.log("# onMobileSubTopbarMenuClick type=cog ..");
        // AppConfig.toggleConfigurator();
        // need to fire .click on button.id = layout-config-button
        // let button = document.getElementById("layout-config-button");
        // let button = document.getElementById("toggleMenuClick");
        // console.log("# button = ", button);
        //
        // JDG: this blick does not work, maybe better directly affect 'state', but need to extract state from component to higher level ..
        // button.click();
        //
        // commonState.activeAppConfig = !commonState.activeAppConfig;
        //
        // jdg d220304 here is the working solution:
        // set_appconfig_active(true);
        set_appconfig_active((prevState) => !prevState);
        //
        break;
      case "user":
        // console.log("# onMobileSubTopbarMenuClick type=user ..");
        break;
      default:
        // console.log("# onMobileSubTopbarMenuClick type=<none> ..");
        break;
    }
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const app_menu = [
    {
      label: "TokenMe-v3",
      items: [
        // { label: "Dashboard", icon: "pi pi-fw pi-wifi", to: "/v3/dashboard" },
        { label: "Dashboard [live]", icon: "pi pi-fw pi-home", to: "/" },
        { label: "Floorplan", icon: "pi pi-fw pi-map", to: "/v3/floorplan" },
        { label: "Anchors [live]", icon: "pi pi-fw pi-wifi", to: "/v3/anchor-data" },
        { label: "Tokens [live]", icon: "pi pi-fw pi-compass", to: "/v3/token-data" },
        { label: "Anchor [edit]", icon: "pi pi-fw pi-wifi", to: "/anchor-detail" },
        { label: "Anchor-Groups", icon: "pi pi-fw pi-th-large", to: "/v3/anchorgroups" },
        { label: "Token-Groups", icon: "pi pi-fw pi-th-large", to: "/v3/tokengroups" },
        { label: "cdfuncs", icon: "pi pi-fw pi-share-alt", to: "/cdfuncs" },
        { label: "Config Types", icon: "pi pi-fw pi-share-alt", to: "/configtypes" },
        { label: "Config Messages", icon: "pi pi-fw pi-envelope", to: "/configmessages" },
        { label: "Events", icon: "pi pi-fw pi-compass", to: "/v3/events" },
        { label: "MQTT-Sub", icon: "pi pi-fw pi-map", to: "/mqtt" },
        { label: "MQTT-Pub", icon: "pi pi-fw pi-map", to: "/mqttpub" },
        { label: "Messages-Q", icon: "pi pi-fw pi-envelope", to: "/qmessages" },
        { label: "Design", icon: "pi pi-fw pi-map", to: "/design" },
        { label: "Admin", icon: "pi pi-fw pi-cog", to: "/admin" },
        // { label: "tablecolors", icon: "pi pi-fw pi-cog", to: "/tablecolors" },
      ],
    },
    // {
    //   label: "TokenMe-v2",
    //   items: [
    //     // {label: 'tmc5dash', icon: 'pi pi-fw pi-home', to: '/'},
    //     { label: "Overview", icon: "pi pi-fw pi-home", to: "/overview" },
    //     { label: "Floorplan", icon: "pi pi-fw pi-map", to: "/maps" },
    //     // https://www.primefaces.org/primereact/icons/
    //     // {label: 'Reports',    icon: 'pi pi-fw pi-book',         to: '/reports'},
    //     { label: "Anchors", icon: "pi pi-fw pi-wifi", to: "/anchors" },
    //     { label: "Anchor Health", icon: "pi pi-fw pi-chart-line", to: "/anchorhealth" },
    //     { label: "Tokens", icon: "pi pi-fw pi-compass", to: "/tokens" },
    //     { label: "Timepoints", icon: "pi pi-fw pi-calendar-plus", to: "/timepoints" },
    //     // { label: 'CrudPage', icon: 'pi pi-fw pi-user-edit', to: '/crud' },
    //     { label: "Dashboard", icon: "pi pi-fw pi-map", to: "/dashboard" },
    //     { label: "Status", icon: "pi pi-fw pi-heart", to: "/status" },
    //   ],
    // },
    {
      label: "UI-demo",
      icon: "pi pi-fw pi-sitemap",
      items: [
        { label: "Demo/Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard_old" },
        // { label: "Form Layout", icon: "pi pi-fw pi-id-card", to: "/formlayout" },
        // { label: "Input", icon: "pi pi-fw pi-check-square", to: "/input" },
        // { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
        // { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
        // { label: "Button", icon: "pi pi-fw pi-mobile", to: "/button" },
        // { label: "Table", icon: "pi pi-fw pi-table", to: "/table" },
        // { label: "List", icon: "pi pi-fw pi-list", to: "/list" },
        // { label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree" },
        // { label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel" },
        // { label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay" },
        // { label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu" },
        // { label: "Message", icon: "pi pi-fw pi-comment", to: "/messages" },
        // { label: "File", icon: "pi pi-fw pi-file", to: "/file" },
        { label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
        // { label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc" },
        // //     ],
        // // },
        // // {
        // //     label: 'Pages', icon: 'pi pi-fw pi-clone',
        // //     items: [
        { label: "CRUD", icon: "pi pi-fw pi-user-edit", to: "/crud" },
        // { label: "Timeline", icon: "pi pi-fw pi-calendar", to: "/timeline" },
        // { label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty" },
      ],
    },
    //
  ]; // const app_menu = []
  //
  // eslint-disable-next-line
  const disabled__menu = [
    {
      label: "Menu Hierarchy",
      icon: "pi pi-fw pi-search",
      items: [
        {
          label: "Submenu 1",
          icon: "pi pi-fw pi-bookmark",
          items: [
            {
              label: "Submenu 1.1",
              icon: "pi pi-fw pi-bookmark",
              items: [
                { label: "Submenu 1.1.1", icon: "pi pi-fw pi-bookmark" },
                { label: "Submenu 1.1.2", icon: "pi pi-fw pi-bookmark" },
                { label: "Submenu 1.1.3", icon: "pi pi-fw pi-bookmark" },
              ],
            },
            {
              label: "Submenu 1.2",
              icon: "pi pi-fw pi-bookmark",
              items: [
                { label: "Submenu 1.2.1", icon: "pi pi-fw pi-bookmark" },
                { label: "Submenu 1.2.2", icon: "pi pi-fw pi-bookmark" },
              ],
            },
          ],
        },
        {
          label: "Submenu 2",
          icon: "pi pi-fw pi-bookmark",
          items: [
            {
              label: "Submenu 2.1",
              icon: "pi pi-fw pi-bookmark",
              items: [
                { label: "Submenu 2.1.1", icon: "pi pi-fw pi-bookmark" },
                { label: "Submenu 2.1.2", icon: "pi pi-fw pi-bookmark" },
                { label: "Submenu 2.1.3", icon: "pi pi-fw pi-bookmark" },
              ],
            },
            {
              label: "Submenu 2.2",
              icon: "pi pi-fw pi-bookmark",
              items: [
                { label: "Submenu 2.2.1", icon: "pi pi-fw pi-bookmark" },
                { label: "Submenu 2.2.2", icon: "pi pi-fw pi-bookmark" },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "Get Started",
      items: [
        {
          label: "Documentation",
          icon: "pi pi-fw pi-question",
          command: () => {
            window.location = "#/documentation";
          },
        },
        {
          label: "View Source",
          icon: "pi pi-fw pi-search",
          command: () => {
            window.location = "https://github.com/primefaces/sakai-react";
          },
        },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  const [appconfig_active, set_appconfig_active] = useState(false);

  return (
    // <SocketContext.Provider value={socket_io}>
    <div className={wrapperClass} onClick={onWrapperClick}>
      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu model={app_menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          {/*  */}
          {/* <React.StrictMode>  ===> guide: https://reactjs.org/docs/strict-mode.html */}
          <React.StrictMode>
            {/*  */}
            <ErrorBoundary>
              {/* <Route path="/" exact component={TMC5dash}/> */}
              <Switch>
                <Route path="/maintenance" component={Maintenance} />
	  {/* <Redirect from="*" to="/maintenance" /> */}

                <Route path="/overview" exact component={OverviewPage} />
                {/* <Route path="/"       exact component={AnchorsPage}/> */}
                {/* <Route path="/reports"      component={ReportsPage}/> */}
                <Route path="/" exact component={DashboardV3Page} />
                <Route path="/v3/dashboard" component={DashboardV3Page} />
                <Route path="/design" component={DesignPage} />
                <Route path="/v3/floorplan" component={FloorplanV3Page} />
                <Route path="/v3/anchor-data" component={AnchorV3DataPage} />
                <Route path="/anchor-detail/:tpid?" component={PageAnchorDetail} />
                {/* alternative way to "call component", by using "render" and a func, BUT also send old-props using special JSX operator: {...props}
                <Route
                  path="/anchor-detail/:tpid?"
                  render={(props) => (
                    <PageAnchorDetail
                      sortBy="newest"
                      {...props}
                    />
                  )}
                /> */}
                <Route path="/v3/token-data" component={TokenV3DataPage} />
                <Route path="/tablecolors" component={TableColors} />
                <Route path="/v3/anchorgroups" component={PageAnchorGroups} />
                <Route path="/v3/tokengroups" component={TokenGroups} />
                <Route path="/cdfuncs" component={PageCdfuncs} />
                <Route path="/configtypes" component={PageConfigTypes} />
                <Route path="/configmessages" component={PageConfigMessages} />
                <Route path="/v3/events" component={EventsPage} />
                <Route path="/anchors" component={AnchorsPage} />
                <Route path="/anchorhealth" component={AnchorHealthPage} />
                <Route path="/tokens" component={TokensPage} />
                <Route path="/timepoints" component={TimepointsPage} />
                <Route path="/maps" component={MapsPage} />
                <Route path="/mqtt" component={MqttPage} />
                <Route path="/mqttpub" component={MqttPubPage} />
                <Route path="/qmessages" component={QMessagesPage} />
                {/* <Route path="/crud" component={CrudPage} /> */}
                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/status" component={StatusPage} />
                <Route path="/admin" component={AdminPage} />
                {/*  */}
                <Route path="/dashboard_old" component={Dashboard} />
                <Route path="/formlayout" component={FormLayoutDemo} />
                <Route path="/input" component={InputDemo} />
                <Route path="/floatlabel" component={FloatLabelDemo} />
                <Route path="/invalidstate" component={InvalidStateDemo} />
                <Route path="/button" component={ButtonDemo} />
                <Route path="/table" component={TableDemo} />
                <Route path="/list" component={ListDemo} />
                <Route path="/tree" component={TreeDemo} />
                <Route path="/panel" component={PanelDemo} />
                <Route path="/overlay" component={OverlayDemo} />
                <Route path="/menu" component={MenuDemo} />
                <Route path="/messages" component={MessagesDemo} />
                <Route path="/file" component={FileDemo} />
                <Route path="/chart" component={ChartDemo} />
                <Route path="/misc" component={MiscDemo} />
                <Route path="/timeline" component={TimelineDemo} />
                <Route path="/crud" component={CrudPage} />
                <Route path="/empty" component={EmptyPage} />
                <Route path="/documentation" component={Documentation} />
              </Switch>
            </ErrorBoundary>
            {/*  */}
          </React.StrictMode>
        </div>
        {/* <AppFooter layoutColorMode={layoutColorMode}/> */}
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
        //  commonState={commonState}
        appconfig_active={appconfig_active}
        set_appconfig_active={set_appconfig_active}
      />

      <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
    // </SocketContext.Provider>
  );
}; // \const App = () => {}

export default App;

// console.log("# EOF App.js ..");
//-EOF
