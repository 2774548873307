// import React from 'react';

// import React, { useRef, useState } from 'react';
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

// eslint-disable-next-line
// some..

// https://axios-http.com/
// https://www.npmjs.com/package/axios
// https://github.com/axios/axios
// https://www.digitalocean.com/community/tutorials/js-axios-vanilla-js
import axios from "axios"; // npm i axios --save
// axios.get('/users')
//   .then(res => {
//     console.log(res.data);
//   })
// ;

export const AdminPage = () => {
  const toast = useRef();

  // eslint-disable-next-line
  // const url_sys_ping      = "./api/sys-ping/";
  // const url_sys_showgen   = "./api/sys-showgen/";
  // const url_sys_rpc       = "./api/sys-rpc/";

  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_SIT_URL;
  //
  const url_sys_ping = api_url + "/api/sys-ping";
  const url_sys_dbcheck = api_url + "/api/sys-dbcheck";
  const url_sys_showgen = api_url + "/api/sys-showgen";
  const url_sys_rpc = api_url + "/api/sys-rpc";

  const url_dev_links = {
    url_sys_ping: url_sys_ping,
    "window._env_.API_SIT_URL": window._env_.API_SIT_URL,
    "window._env_.API_EXT_URL": window._env_.API_EXT_URL,
    "window._env_.API_INT_URL": window._env_.API_INT_URL,
    "window._env_.CFG_API_DEV_URL": window._env_.CFG_API_DEV_URL,
    "window._env_.CFG_WEBUI_DEV_URL": window._env_.CFG_WEBUI_DEV_URL,
    "window._env_.CFG_MONGOEX_DEV_URL": window._env_.CFG_MONGOEX_DEV_URL,
    "window._env_.CFG_POSTADMIN_DEV_URL": window._env_.CFG_POSTADMIN_DEV_URL,
  };

  // const url_conffile = api_url + "/env-config.js";
  // const url_conffile = api_url + "/env-config.json";
  const url_conffile = api_url + "/web/env-config.json";

  const clickClear = () => {
    //
    // window.open(url_sys_ping);
    //
    axios
      .post(url_sys_rpc, {
        proc_name: "clear_db_import_test",
      })
      .then((response) => {
        // console.log(response.data);
        const result_obj = {
          status: response.data.proc_output.status,
          result: response.data.proc_output.values.result,
        };
        let msg_detail = JSON.stringify(result_obj.result);
        // msg_detail = msg_detail.replace(/(?:\r\n|\r|\n)/g, '<br>');
        msg_detail = msg_detail.replace(/(?:,)/g, ", ");
        toast.current.show({ severity: "success", summary: "Clear Success", detail: msg_detail, life: 9123 });
      })
      .catch((error) => {
        const msg_detail = JSON.stringify(error);
        toast.current.show({ severity: "error", summary: "Clear Failed", detail: msg_detail, life: 9123 });
      });
  };

  const click_token_clear = () => {
    //
    // window.open(url_sys_ping);
    //
    axios
      .post(url_sys_rpc, {
        proc_name: "clear_db_tokens",
      })
      .then((response) => {
        // console.log(response.data);
        const result_obj = {
          status: response.data.proc_output.status,
          result: response.data.proc_output.values.result,
        };
        let msg_detail = JSON.stringify(result_obj.result);
        // msg_detail = msg_detail.replace(/(?:\r\n|\r|\n)/g, '<br>');
        msg_detail = msg_detail.replace(/(?:,)/g, ", ");
        toast.current.show({ severity: "success", summary: "Clear Success", detail: msg_detail, life: 9123 });
      })
      .catch((error) => {
        const msg_detail = JSON.stringify(error);
        toast.current.show({ severity: "error", summary: "Clear Failed", detail: msg_detail, life: 9123 });
      });
  };

  const click_anchor_clear = () => {
    //
    // window.open(url_sys_ping);
    //
    axios
      .post(url_sys_rpc, {
        proc_name: "clear_db_anchors",
      })
      .then((response) => {
        // console.log(response.data);
        const result_obj = {
          status: response.data.proc_output.status,
          result: response.data.proc_output.values.result,
        };
        let msg_detail = JSON.stringify(result_obj.result);
        // msg_detail = msg_detail.replace(/(?:\r\n|\r|\n)/g, '<br>');
        msg_detail = msg_detail.replace(/(?:,)/g, ", ");
        toast.current.show({ severity: "success", summary: "Clear Success", detail: msg_detail, life: 9123 });
      })
      .catch((error) => {
        const msg_detail = JSON.stringify(error);
        toast.current.show({ severity: "error", summary: "Clear Failed", detail: msg_detail, life: 9123 });
      });
  };

  const click_token_clear_v3 = () => {
    //
    // window.open(url_sys_ping);
    //
    axios
      .post(url_sys_rpc, {
        proc_name: "clear_db_tokens_v3",
      })
      .then((response) => {
        // console.log(response.data);
        const result_obj = {
          status: response.data.proc_output.status,
          result: response.data.proc_output.values.result,
        };
        let msg_detail = JSON.stringify(result_obj.result);
        // msg_detail = msg_detail.replace(/(?:\r\n|\r|\n)/g, '<br>');
        msg_detail = msg_detail.replace(/(?:,)/g, ", ");
        toast.current.show({ severity: "success", summary: "Clear Success", detail: msg_detail, life: 9123 });
      })
      .catch((error) => {
        const msg_detail = JSON.stringify(error);
        toast.current.show({ severity: "error", summary: "Clear Failed", detail: msg_detail, life: 9123 });
      });
  };

  const click_anchor_clear_v3 = () => {
    //
    // window.open(url_sys_ping);
    //
    axios
      .post(url_sys_rpc, {
        proc_name: "clear_db_anchors_v3",
      })
      .then((response) => {
        // console.log(response.data);
        const result_obj = {
          status: response.data.proc_output.status,
          result: response.data.proc_output.values.result,
        };
        let msg_detail = JSON.stringify(result_obj.result);
        // msg_detail = msg_detail.replace(/(?:\r\n|\r|\n)/g, '<br>');
        msg_detail = msg_detail.replace(/(?:,)/g, ", ");
        toast.current.show({ severity: "success", summary: "Clear Success", detail: msg_detail, life: 9123 });
      })
      .catch((error) => {
        const msg_detail = JSON.stringify(error);
        toast.current.show({ severity: "error", summary: "Clear Failed", detail: msg_detail, life: 9123 });
      });
  };

  const clickPopulate = () => {
    axios
      .post(url_sys_rpc, {
        proc_name: "populate_import_test",
      })
      .then((response) => {
        const result_obj = {
          status: response.data.proc_output.status,
          result: response.data.proc_output.values.result,
        };
        let msg_detail = JSON.stringify(result_obj.result);
        // msg_detail = msg_detail.replace(/(?:\r\n|\r|\n)/g, '<br>');
        msg_detail = msg_detail.replace(/(?:,)/g, ", ");
        toast.current.show({ severity: "success", summary: "Populate Success", detail: msg_detail, life: 9123 });
      })
      .catch((error) => {
        const msg_detail = JSON.stringify(error);
        toast.current.show({ severity: "error", summary: "Populate Failed", detail: msg_detail, life: 9123 });
      });
  };

  const clickShowgen = () => {
    // window.open('/api/sys-showgen');
    window.open(url_sys_showgen);
  };

  const clickPing = () => {
    window.open(url_sys_ping);
  };

  const clickDBcheck = () => {
    window.open(url_sys_dbcheck);
  };

  const clickSHOWCONF = () => {
    window.open(url_conffile);
  };

  const ButtonLink = (props) => {
    return <Button label={props.name + " = " + props.link} onClick={() => window.open(props.link)} className="p-button-raised p-button-success p-1 mb-2 mono" />;
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <h5>Admin Page</h5>

          <div className="grid">
            {/* <div className="col-12 md:col-6"> */}
            <div className="col-12">
              <Toast ref={toast} />

              <div className="card">
                <h5>Clear Database</h5>
                <Button label="CLEAR DB" onClick={clickClear} className="p-button-raised p-button-danger  mr-2 mb-2" /> <br />
                {/* <Button type="button" onClick={showSuccess} label="Success" className="p-button-success mr-2" /> */}
                {/* <a href={url_sys_ping} target="_blank" rel="noopener" >{url_sys_ping}</a> */}
                {/* <a href={url_sys_ping} target="_blank">{url_sys_ping}</a> */}
                <Button label="CLEAR Tokens" onClick={click_token_clear} className="p-button-raised p-button-danger  mr-2 mb-2" /> <br />
                <Button label="CLEAR Anchors" onClick={click_anchor_clear} className="p-button-raised p-button-danger  mr-2 mb-2" /> <br />
                <Button label="CLEAR TokensV3" onClick={click_token_clear_v3} className="p-button-raised p-button-danger  mr-2 mb-2" /> <br />
                <Button label="CLEAR AnchorsV3" onClick={click_anchor_clear_v3} className="p-button-raised p-button-danger  mr-2 mb-2" /> <br />
              </div>

              <div className="card">
                <h5>Populate Test-data</h5>
                <Button label="POPULATE" onClick={clickPopulate} className="p-button-raised p-button-warning mr-2 mb-2" />
              </div>

              <div className="card">
                <h5>Show generated data</h5>
                <Button label="SHOW" onClick={clickShowgen} className="p-button-raised p-button-success mr-2 mb-2" />
              </div>

              <div className="card">
                <h5>Ping the API</h5>
                <Button label="PING" onClick={clickPing} className="p-button-raised p-button-success mr-2 mb-2" />
              </div>

              <div className="card">
                <h5>DBcheck on the API</h5>
                <Button label="DBCHECK" onClick={clickDBcheck} className="p-button-raised p-button-success mr-2 mb-2" />
              </div>

              {/* 
              <div className="card">
                { / * windows._env_.API_SIT_URL = {window._env_.API_SIT_URL} * / }
                windows._env_.API_SIT_URL = <ButtonLink link={window._env_.API_SIT_URL} />
              </div>

              <div className="card">
                { / * windows._env_.PROXY_URL = {window._env_.PROXY_URL}  * / }
                windows._env_.PROXY_URL = <ButtonLink link={window._env_.PROXY_URL} />
              </div> 
              */}

              <div className="card">
                <h5>Show the client-side config file</h5>
                <Button label="SHOWCONF" onClick={clickSHOWCONF} className="p-button-raised p-button-success mr-2 mb-2" />
              </div>

              <div className="card">
                <h5>TableColors</h5>
                <Link to="/tablecolors">TableColors</Link>
              </div>

              <div className="card">
                <h5>Dev Links</h5>
                {Object.entries(url_dev_links).map(([i_key, i_val]) => {
                  // console.log(`# i_key=${i_key} , i_val=${i_val} `);
                  if (i_key) {
                    return (
                      <>
                        {/* <Link to={i_key}>{i_key}</Link> */}
                        {/* <Button label={i_key} onClick={() => window.open(i_key)} className="p-button-raised p-button-success mr-2 mb-2" /> */}
                        <ButtonLink name={i_key} link={i_val} />
                        <br />
                      </>
                    );
		  } else {
			  return undefined;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
