//= ./src/components/TableToken.jsx

import React, { useState, Component, useContext, useEffect } from "react";

import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable/
import { Column } from "primereact/column";
// import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
// import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
// import { ProgressBar } from "primereact/progressbar";
// import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";

// import { CrudTable } from "../CrudTable";

import axios from "axios"; // npm i axios --save
import moment_js from "moment"; // npm i moment --save

import { f_max_age_ms, f_get_max_age_both } from "../config/config";

import { f_convert_sensors_to_display_string, f_get_epoch_now, f_is_object, f_js, f_tpid_is_token } from "../lib/functions";
import TokenPill from "./TokenPill";

// const max_age = f_max_age_ms();
const { max_age_anchor, max_age_token } = f_get_max_age_both();

const TableToken = (props) => {
  //

  const [token_table, set_token_table] = useState(null);
  const [token_filter, set_token_filter] = useState("");
  const [token_loading, set_token_loading] = useState(true);
  const [token_show_id, set_token_show_id] = useState(false);
  const [token_checkbox_show_id, set_token_checkbox_show_id] = useState([]);
  //
  // Date.now() // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now // "returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC."
  const [interval_time, set_interval_time] = useState(Date.now());
  const [sortField, setSortField] = useState("tpid");
  const [sortOrder, setSortOrder] = useState(1);

  useEffect(() => {
    //
    update_api_tokens();
    //
    const interval = setInterval(() => {
      set_interval_time(Date.now());
      update_api_tokens();
    }, update_interval_s * 1000);
    //
    // return a function that is to be run at 'componentWillUnmount':
    return () => {
      clearInterval(interval); // undo 'setInterval()'
    };
    //
    // eslint-disable-next-line
  }, []);

  const date_to_nicetime = (date) => {
    return moment_js(date).format("YYYY-MMM-DD/HH:mm:ss");
  };

  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  const url_tokens_v3 = api_url + "/api/tokens";

  let update_interval_s = 2;

  const update_api_tokens = () => {
    // set_token_loading(true);
    console.log(url_tokens_v3)
    axios
      .get(url_tokens_v3, {}, { timeout: 3000 })
      .then((data) => {
        let tokens = data.data && data.data.tokens ? data.data.tokens : null;
        //
        const filter_non_tpid = true;
        if (filter_non_tpid) {
          const tokens_non_tpid = tokens.filter((item) => {
            return f_tpid_is_token(item.tpid);
          });
          tokens = tokens_non_tpid;
        }
        //
        if (props.show_items_limit === "recent") {
          const anchors_new = tokens.filter((item) => {
            // let time_data = new Date(item.time * 1000);
            // let time_now = Date.now();
            // const is_old = time_data < time_now - max_age * 2;
            const time_now = f_get_epoch_now();
            const is_old = item.time < time_now - Math.round(max_age_token * 2);
            return !is_old;
          });
          tokens = anchors_new;
        }
        set_token_table(tokens);
        set_token_loading(false);
      })
      .catch((err) => {
        set_token_table(null);
        set_token_loading(false);
      });
  };

  const f_ui_open_api = () => {
    window.open(url_tokens_v3);
  };

  // const token_table_header = (
  const token_table_header = () => {
    const last_api_update_str = `Last API update: ${date_to_nicetime(interval_time)} (max_age_token=${max_age_token}s)`;
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        {/* <div className="table-header"> */}
        <h5 className="m-0">Token</h5>

        <div className="my-2">
          <Chip className="my-2 mr-2" label={last_api_update_str} />
          <Button label="Open API" icon="pi pi-external-link" onClick={f_ui_open_api} className="p-button-sm p-button-warning mr-2 my-2" />
        </div>

        {/* <span className="p-input-icon-left"> */}
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={token_filter} onChange={(e) => set_token_filter(e.target.value)} placeholder="Global Search" />
        </span>
      </div>
    );
  };

  // JDG-Todo: replace this with lodash.get()
  const f_object_lookup_default = function ({ obj, field, dflt }) {
    const ret_value = f_is_object(obj) && obj.hasOwnProperty(field) ? obj[field] : dflt;
    return ret_value;
  };

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {f_object_lookup_default({ obj: data, field: props.field, dflt: "" })}
      </>
    );
  };

  const token_template = (data, props) => {
    const tpid = f_object_lookup_default({ obj: data, field: props.field, dflt: "T-00" });
    // const hexstr = tid.split("-")[1];
    // return <TokenPill hexstr={hexstr} />;
    return <TokenPill tpid={tpid} />;
  };

  const X_anchors_template = (data, props) => {
    const tokens = f_object_lookup_default({ obj: data, field: "tokens", dflt: {} });
    const token_ids = Object.keys(tokens);
    token_ids.sort(); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    //
    // const color = getColorFromNumber(hexnr);
    //
    // let result_string = token_ids.join(",");
    // return (
    //   <>
    //     <span className="p-column-title">{props.header}</span>
    //     {result_string}
    //   </>
    // );
    //
    // const some = (
    //   <svg>
    //     <g transform="translate(20,20)">
    //       <circle
    //         cx="0"
    //         cy="0"
    //         r="20"
    //         fill={color}
    //       />
    //       <text
    //         x="-8"
    //         y="5"
    //         stroke="white"
    //         fill="white"
    //         className="mono"
    //       >
    //         {20}
    //       </text>
    //     </g>
    //   </svg>
    // );
    //
    // const some2 = (
    //   <span
    //     type="button"
    //     class="btn btn-primary btn-sm"
    //     style={{
    //       padding: "2px 4px",
    //       border: `2px solid ${color}`,
    //       borderRadius: "6px",
    //       color: "white",
    //       backgroundColor: color,
    //       fontSize: "16px",
    //       fontFamily: "monospace",
    //       fontWeight: "bold",
    //       marginRight: "6px",
    //     }}
    //   >
    //     {`T-${hexnr}`}
    //   </span>
    // );
    // return (
    //   <>
    //     <span className="p-column-title">{props.header}</span>
    //     {some2}
    //   </>
    // );
    //
    return (
      <div>
        {token_ids.map((tpid) => {
          // const hexstr = tid.split("-")[1];
          // return <TokenPill hexstr={hexstr} />;
          return <TokenPill tpid={tpid} />;
        })}
      </div>
    );

    //
  };

  const reptime_template = (data, props) => {
    const time = data.time || 0;
    const time_now = f_get_epoch_now();
    const data_time_str = data.time_str;
    const time_diff = time_now - time;
    const top_str = `${time} (${time_diff}s)`;
    const bot_str = `${data_time_str}`;
    const data_formatted = (
      <>
        {top_str} <br /> {bot_str}
      </>
    );
    const is_old = time < time_now - Math.round(max_age_token * 1);
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        <span className={is_old ? "span_grey" : "span_bold_blue"}>{data_formatted}</span>
      </>
    );
  };

  const anchors_template = (data, props) => {
    const anchors = f_object_lookup_default({ obj: data, field: "anchors", dflt: {} });
    let anchor_ids = Object.keys(anchors);
    //
    const time_now = f_get_epoch_now();
    anchor_ids = anchor_ids.filter((anc) => anchors[anc].time < time_now - Math.round(max_age_token * 1));
    //
    anchor_ids.sort(); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    //
    // let result_string = anchor_ids.join(",");
    let result_string = (
      <div>
        {anchor_ids.map((tpid) => {
          // const hexstr = tid.split("-")[1];
          // return <TokenPill hexstr={hexstr} />;
          return <TokenPill tpid={tpid} />;
        })}
      </div>
    );

    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const sensors_template = (data, props) => {
    // const sensors = f_object_lookup_default({ obj: data, field: "aux", dflt: {} });
    const sensors = f_object_lookup_default({ obj: data, field: "sensors", dflt: {} });
    //
    let result_string = f_convert_sensors_to_display_string({ sensors });
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const cdf_template = (data, props) => {
    const cdf_obj = f_object_lookup_default({ obj: data, field: "cdf", dflt: {} });
    //
    // let result_string = f_convert_selected_sensors_to_display_string({ sensors }); // wens Hans
    let result_string = JSON.stringify(cdf_obj);
    // result_string = result_string.replace(":", ":: ");
    // result_string = result_string.replace(",", ",, ");
    result_string = result_string.replace(/([:,])/g, "$1 ");
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  return (
    //   {/* https://www.primefaces.org/primereact/datatable/ */}
    <DataTable
      className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
      header={token_table_header}
      value={token_table}
      dataKey="_id"
      loading={token_loading}
      emptyMessage="No items found."
      globalFilter={token_filter}
      // scrollable scrollHeight="600px"
      //
      sortField={sortField}
      sortOrder={sortOrder}
      onSort={onSort}
      //
      // sortMode="multiple"
      // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
      //
      // paginator rows={24}
      rowHover
      // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
      size="small"
      // columnResizeMode="fit"
      autoLayout="true"
      resizableColumns
      //
    >
      {token_show_id && <Column field="id" header="ID" sortable body={body_template} />}
      <Column field="tpid" header="Token-ID" sortable body={token_template} />
      <Column field="display_name" header="Display Name" sortable body={body_template} style={{ whiteSpace: "normal" }} />
      {/* <Column
      field="_uplink_tech"
      header="Uplink:Tech"
      sortable
      body={body_template}
    />
    <Column
      field="_uplink_prov"
      header="Uplink:Prov"
      sortable
      body={body_template}
    /> */}
      {/* <Column
      field="_uplink_health"
      header="Uplink:Health"
      sortable
      body={body_template}
      // style={{ whiteSpace: "normal" }} // undo:App.scss : .p-datatable-tbody { white-space: nowrap;}
    /> */}
      <Column
        field="time_str"
        header="Last update"
        sortable
        body={reptime_template}
        // style={{ whiteSpace: "normal" }}
      />
      <Column field="anchors" header="Anchors" sortable body={anchors_template} style={{ width: "80%", whiteSpace: "normal" }} />
      <Column field="sensors" header="Sensors" sortable body={sensors_template} style={{ width: "70%", whiteSpace: "normal" }} />
      <Column field="cdf" header="cdfuncs" sortable body={cdf_template} style={{ width: "70%", whiteSpace: "normal" }} />
    </DataTable>

    // /* - - - - - - = = = - - - - - - */
    // /* <CrudTable meta_prop={meta_prop} /> */
    // /* - - - - - - = = = - - - - - - */
  );
}; // \const TableToken = (props) => {}

export default TableToken;

//-eof
