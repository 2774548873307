//= ./src/pages/TokenGroups.jsx

import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
//
import { TableTemplate } from "../components/common/TableTemplate";

// - - - - - - = = = - - - - - -
const mod_name = "pages_TokenGroups";
const func_name = `${mod_name}:anonymous`; // <== default, needs to be locally overwritten

// Icons =>        https://www.primefaces.org/primereact/icons/
// CSS =>          https://www.primefaces.org/primeflex/
// Componenets =>  https://www.primefaces.org/primereact/calendar/

const api_url = window._env_.API_BASE_URL;
const url_api_v3_tokengroup = api_url + "/api/v3/tokengroup";

export const TokenGroups = (props) => {
  //
  //   api_url: "https://cloud52.sostark.nl/code/75114096/dashboard/api/v3/tokengroup",

  const model = {
    api_url: url_api_v3_tokengroup,
    item_name_singular: "Token-Group",
    item_name_plural: "Token-Groups",
    table_title: "Token-Groups",
    dialog_title: "Token-Group Details",
    dialog_width: "450px",
    msg_empty: "No Token-Groups found.",
    msg_confirm_dialog_header: "Confirm",
    msg_are_you_sure_delete_single_item: "Are you sure you want to delete",
    msg_are_you_sure_delete_multi_item: "Are you sure you want to delete the selected Token-Groups?",
    fields: [
      {
        field: "id",
        label: "ID",
        dialog_type: "id",
        focus: false,
        table_type: "string",
        required: false,
        sortable: true,
      },
      {
        field: "tpid",
        label: "TPID",
        dialog_type: "tpid",
        focus: false,
        table_type: "tpid",
        required: false,
        sortable: true,
      },
      {
        field: "display_name",
        label: "Name",
        dialog_type: "string",
        focus: true,
        table_type: "string",
        required: true,
        sortable: true,
        cols_in_dialog: 12,
      },
      {
        field: "members",
        label: "Members",
        dialog_type: "array-tpid",
        focus: false,
        table_type: "array-tpid",
        required: true,
        sortable: false,
        cols_in_dialog: 12,
      },
    ],
    table_fields_order: ["tpid", "display_name", "members"],
    dialog_fields_order: ["tpid", "display_name", "members"],
    main_field: "display_name",
    initial_sort_field: "display_name",
    data_key: "id",
    rows: 10,
    rows_per_page_options: [5, 10, 25, 50, 100],
    operations: ["new", "edit", "delete"], // all options: 'new', 'view', 'edit', 'duplicate', 'delete' // NVEDD
    show_showid_button: true,
    show_openapi_button: true,
    show_refresh_button: true,
    auto_refresh_secs: 0,
    auto_refresh_options: [0, 1, 3, 5, 10, 30, 60],
    new_item_template: {
      display_name: "",
    },
    image_path: "assets/demo/images/item",
    //
    fa_model_get_items: undefined,
    fa_model_set_item: undefined,
    //
  };

  // - - - - - - = = = - - - - - -
  // API logic:

  const fa_model_get_items = async function () {
    //
    let get_items = undefined;
    axios
      .get(model.api_url, {}, { timeout: 3000 })
      .then((response) => {
        get_items = response.data && response.data.tokengroups ? response.data.tokengroups : null;
        f_set_items(get_items); // this will cause a re-render
      })
      .catch((err) => {});
    //
  };

  const fa_model_set_item = async function ({ action, item }) {
    const func_name = "fa_model_set_item";
    //
    let updated_item = undefined;
    // console.log(`${func_name} action=${action}`);
    //
    return new Promise(async (resolve, reject) => {
      try {
        switch (action) {
          case "new":
            break;
          case "update":
            break;
          case "deleteOne":
            break;
          case "deleteMany":
            break;
          default:
            throw new Error("unknown action");
        }
        resolve(updated_item);
      } catch (err) {
        reject(err);
      }
    });
    //
  };

  model.fa_model_get_items = fa_model_get_items;
  model.fa_model_set_item = fa_model_set_item;

  // - - - - - - = = = - - - - - -
  // LOCAL STATE:
  const [items, f_set_items] = useState(null);

  // - - - - - - = = = - - - - - -
  // Render logic:
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <TableTemplate parent_items={items} model={model} />
        </div>
      </div>
    </div>
    // - - - - - - = = = - - - - - -
  ); // \return ()
  // - - - - - - = = = - - - - - -
};

//-eof
