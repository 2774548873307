//= pages/MQTT.js

// eslint-disable-next-line
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable/
import { Column } from "primereact/column";
// import { Rating } from 'primereact/rating';
import { Button } from "primereact/button";
// import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
// import { ProgressBar } from 'primereact/progressbar';
import { Checkbox } from "primereact/checkbox";

import axios from "axios"; // npm i axios --save

// import { CustomerService } from '../service/CustomerService';
// (is:)
// import axios from 'axios'
// export class CustomerService {
//     getCustomersMedium() {
//         return axios.get('assets/demo/data/customers-medium.json')
//             .then(res => res.data.data);
//     }
//     getCustomersLarge() {
//         return axios.get('assets/demo/data/customers-large.json')
//                 .then(res => res.data.data);
//     }
// }

// eslint-disable-next-line
const customers_medium_json = {
  data: [
    { id: 1000, name: "James Butt", country: { name: "Algeria", code: "dz" }, company: "Benton, John B Jr", date: "2015-09-13", status: "unqualified", activity: 17, representative: { name: "Ioni Bowcher", image: "ionibowcher.png" } },
    { id: 1001, name: "Josephine Darakjy", country: { name: "Egypt", code: "eg" }, company: "Chanay, Jeffrey A Esq", date: "2019-02-09", status: "proposal", activity: 0, representative: { name: "Amy Elsner", image: "amyelsner.png" } },
    { id: 1002, name: "Art Venere", country: { name: "Panama", code: "pa" }, company: "Chemel, James L Cpa", date: "2017-05-13", status: "qualified", activity: 63, representative: { name: "Asiya Javayant", image: "asiyajavayant.png" } },
  ],
};

export const MqttPage = () => {
  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  const url_mqtt = api_url + "/api/mqtt";

  const [mqtt_table, set_mqtt_table] = useState(null);
  const [mqtt_filter, set_mqtt_filter] = useState("");
  const [mqtt_loading, set_mqtt_loading] = useState(true);
  const [mqtt_show_id, set_mqtt_show_id] = useState(false);
  const [mqtt_checkbox_show_id, set_mqtt_checkbox_show_id] = useState([]);

  useEffect(() => {
    //
    axios
      .get(url_mqtt, {}, { timeout: 3000 })
      .then((data) => {
        set_mqtt_table(data.data.mqtt);
        set_mqtt_loading(false);
      })
      .catch((error) => {
        set_mqtt_table(null);
        set_mqtt_loading(false);
      });
    //
    // eslint-disable-next-line
  }, []);

  const click_show_api_mqtt = () => {
    window.open(url_mqtt);
  };
  const click_refresh_api_mqtt = () => {
    axios
      .get(url_mqtt, {}, { timeout: 3000 })
      .then((data) => {
        set_mqtt_table(data.data.mqtt);
        set_mqtt_loading(false);
      })
      .catch((error) => {
        set_mqtt_table(null);
        set_mqtt_loading(false);
      });
  };
  const mqtt_on_checkbox_showid_change = (e) => {
    let selectedValue = [...mqtt_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_mqtt_checkbox_show_id(selectedValue);
    set_mqtt_show_id(selectedValue.indexOf("true") !== -1);
  };
  //
  const mqtt_table_header = (
    <div className="table-header">
      <h5>MQTT</h5>
      <div className="field-checkbox">
        <Checkbox inputId="checkShowIDs_mqtt" name="option" value="true" checked={mqtt_checkbox_show_id.indexOf("true") !== -1} onChange={mqtt_on_checkbox_showid_change} />
        <label htmlFor="checkShowIDs_mqtt">show IDs</label>
      </div>
      <Button label="/api/mqtt" onClick={click_show_api_mqtt} className="p-button-raised p-button-warning mr-2 mb-2" />
      <Button label="refresh" onClick={click_refresh_api_mqtt} className="p-button-raised p-button-warning mr-2 mb-2" />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={mqtt_filter} onChange={(e) => set_mqtt_filter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  // const [sortField, setSortField] = useState('mqtt_id');
  // const [sortOrder, setSortOrder] = useState(1);
  // const onSort = (e) => {
  //   setSortField(e.sortField);
  //   setSortOrder(e.sortOrder);
  // }

  // const [multiSortMeta, setMultiSortMeta] = useState(
  //   [
  //     {field: '_uplink_tech',  order: -1},
  //     {field: 'mqtt_id',     order: 1}
  //   ]
  // );

  // const mqtt_mongoose_schema = new mongoose.Schema({
  //   name:     { type: String, required: true},
  //   host:     { type: String, required: true},
  //   protocol: { type: String, required: true},
  //   port:     { type: Number, required: true},
  //   topic:    { type: String, required: true},
  //   username: { type: String, required: true},
  //   password: { type: String, required: true},
  // },

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          {/* https://www.primefaces.org/primereact/datatable/ */}
          <DataTable
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            header={mqtt_table_header}
            value={mqtt_table}
            dataKey="_id"
            loading={mqtt_loading}
            emptyMessage="No items found."
            globalFilter={mqtt_filter}
            // scrollable scrollHeight="600px"
            //
            // sortField={sortField} sortOrder={sortOrder} onSort={onSort}
            //
            // sortMode="multiple"
            // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
            //
            paginator
            rows={24}
            rowHover
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            columnResizeMode="fit"
            autoLayout="true">
            {mqtt_show_id && <Column field="_id" header="ID" sortable body={body_template}></Column>}
            <Column field="name" header="Name" sortable body={body_template}></Column>
            <Column field="host" header="Hostname" sortable body={body_template}></Column>
            <Column field="topic" header="Topic" sortable body={body_template}></Column>
            <Column field="username" header="Username" sortable body={body_template}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
}; // \export const OverviewPage = () => {

//-EOF
