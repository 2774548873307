// Maps.js

// eslint-disable-next-line
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Rating } from 'primereact/rating';
import { Button } from "primereact/button";
// import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
// import { ProgressBar } from 'primereact/progressbar';
import { Checkbox } from "primereact/checkbox";

// import { Map } from '../components/Map';
import { TokenMap } from "../components/TokenMap";

import axios from "axios"; // npm i axios --save
// import momentjs from "moment";    // npm i moment --save

// import floorplan from '../layout/images/THT-Floorplan-27-05-2019-bw-small.png';
// import floorplan from '../layout/images/Floorplan_odense-01-white-edit.png';
import floorplan from "../layout/images/Floorplan_odense-01-white-edit-rotated.png";

import { f_max_age_ms, f_get_max_age_both } from "../config/config";
let max_age = f_max_age_ms();

// eslint-disable-next-line
const customers_medium_json = {
  data: [
    { id: 1000, name: "James Butt", country: { name: "Algeria", code: "dz" }, company: "Benton, John B Jr", date: "2015-09-13", status: "unqualified", activity: 17, representative: { name: "Ioni Bowcher", image: "ionibowcher.png" } },
    { id: 1001, name: "Josephine Darakjy", country: { name: "Egypt", code: "eg" }, company: "Chanay, Jeffrey A Esq", date: "2019-02-09", status: "proposal", activity: 0, representative: { name: "Amy Elsner", image: "amyelsner.png" } },
    { id: 1002, name: "Art Venere", country: { name: "Panama", code: "pa" }, company: "Chemel, James L Cpa", date: "2017-05-13", status: "qualified", activity: 63, representative: { name: "Asiya Javayant", image: "asiyajavayant.png" } },
  ],
};

export const MapsPage = () => {
  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  const url_anchors = api_url + "/api/anchors";
  const url_tokens = api_url + "/api/tokens";
  const url_timepoints = api_url + "/api/timepoints";
  const url_maps = api_url + "/api/maps";
  // url_anchors, url_tokens, url_timepoints, url_maps

  // eslint-disable-next-line
  const [map_table, set_map_table] = useState(null);
  const [map_filter, set_map_filter] = useState("");
  // eslint-disable-next-line
  const [map_loading, set_map_loading] = useState(true);
  // eslint-disable-next-line
  const [map_show_id, set_map_show_id] = useState(false);
  const [map_checkbox_show_id, set_map_checkbox_show_id] = useState([]);

  const map_floor1 = {
    map_id: "Floor-1",
    description: "Area of Floor-1 containing 3 maps",
    parent_map: "_top",
    child_maps: ["Map-A91", "Map-A92", "Map-A93", "Map-A94", "Map-A95", "Map-A96"],
    origin: { x: 0.0, y: 0.0 },
    rotate: 0.0,
    size: { width: 60.0, height: 30.0 },
    path: [],
    label_pos: "mid-mid",
  };

  useEffect(() => {
    //
    axios
      .get(url_anchors)
      .then((data) => {})
      .catch((error) => {});
    //
    axios
      .get(url_tokens)
      .then((data) => {})
      .catch((error) => {});
    //
    axios
      .get(url_timepoints)
      .then((data) => {})
      .catch((error) => {});
    //
    axios
      .get(url_maps)
      .then((data) => {
        set_map_table(data.data.maps);
        set_map_loading(false);
      })
      .catch((error) => {
        set_map_table(null);
        set_map_loading(false);
      });
    //
    // eslint-disable-next-line
  }, []);

  const click_show_api_maps = () => {
    window.open(url_maps);
  };
  //
  const map_on_checkbox_showid_change = (e) => {
    let selectedValue = [...map_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_map_checkbox_show_id(selectedValue);
    set_map_show_id(selectedValue.indexOf("true") !== -1);
  };
  //
  // eslint-disable-next-line
  const map_table_header = (
    <div className="table-header">
      <h5>Maps</h5>
      <div className="field-checkbox">
        <Checkbox inputId="checkShowIDs_tpoints" name="option" value="true" checked={map_checkbox_show_id.indexOf("true") !== -1} onChange={map_on_checkbox_showid_change} />
        <label htmlFor="checkShowIDs_tpoints">show IDs</label>
      </div>
      <Button label="/api/maps" onClick={click_show_api_maps} className="p-button-raised p-button-warning mr-2 mb-2" />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={map_filter} onChange={(e) => set_map_filter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  // eslint-disable-next-line
  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  // - - - - - - -  - - = = = = = = = =  = = - - - - - -  - - - - - - - -  -

  // aa65	B24-3
  // aa66	B22-3
  // aa67	B20-1
  // aa68	B24-2
  // aa69	B22-2
  // aa70	B20-2
  // aa71	B24-1
  // aa72	B22-3
  // aa73	B20-3
  // aa74	B22-4
  // aa82	B20-4

  const pos_list = [
    // { aid: 'aa68', x: 80, y: 300 },
    // { aid: 'aa72', x: 200, y: 170 },
    // { aid: 'aa73', x: 600, y: 250 },
    // { aid: 'aa75', x: 180, y: 420 },
    // { aid: 'aa76', x: 420, y: 180 },
    // { aid: 'aa82', x: 450, y: 400 },
    { aid: "aa68", x: 60, y: 220 },
    { aid: "aa72", x: 200, y: 320 },
    { aid: "aa73", x: 440, y: 150 },
    { aid: "aa75", x: 40, y: 360 },
    { aid: "aa76", x: 160, y: 460 },
    { aid: "aa82", x: 450, y: 340 },
  ];

  const tlist_template = (data, props) => {
    // presence_tlist;
    const presence_tlist = data["presence_tlist"];
    const tlist_str = presence_tlist.join(",");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {tlist_str}
      </>
    );
  };

  const [anchor_table, set_anchor_table] = useState(null);
  const [anchor_token_table, set_anchor_token_table] = useState([]);
  const [anchor_loading, set_anchor_loading] = useState(true);

  const c_no_id2 = "f00f";
  const c_no_id = "ffff";

  const c_map_width = 160;
  const c_map_height = 120;
  let v_matrix_cols = 5;
  let v_matrix_rows = 2;
  const floorplan_width = 800; // c_map_width * v_matrix_cols;
  const floorplan_height = 600; // c_map_height * v_matrix_rows;

  const filter_anchor_list_no_id = function (anchor_list) {
    //
    return anchor_list.filter((a) => {
      // filter list on 'true' condition in func
      const test_no_id = a.anchor_id === "" || a.anchor_id === "00" || a.anchor_id === c_no_id || a.anchor_id === c_no_id2;
      return !test_no_id;
    });
  };

  const sort_anchor_list = function (anchors) {
    //
    let date_now = Date.now();
    //
    const anchor_list = anchors.filter((a) => {
      // filter list on 'true' condition in func
      //
      if (a.anchor_id === "" || a.anchor_id === "00" || a.anchor_id === c_no_id || a.anchor_id === c_no_id2) {
        return false;
      }
      //
      const time = a.time || 0;
      let time_date_obj = new Date(time * 1000);
      const is_not_too_old = time_date_obj > date_now - max_age;
      return is_not_too_old;
      //
    });
    //
    let anchor_list_sorted = anchor_list.sort((a, b) => {
      // const a_tech = a._uplink_tech || "00";
      // const b_tech = b._uplink_tech || "00";
      const a_id = a.anchor_id || c_no_id;
      const b_id = b.anchor_id || c_no_id;
      //
      // let  comp = a_tech.localeCompare(b_tech); // return -1,0,1
      // if (comp === 0) {
      //   const comp = a_id.localeCompare(b_id);
      //   // console.log(`# at=${at} bt=${bt} comp=${comp} .`);
      // }
      // return comp;
      //
      return a_id.localeCompare(b_id);
    });
    return anchor_list_sorted;
    //
    // // { label: "1a01", label_pos: "top", anr: 1, width: "300", height: "200", posx: "0",   posy: "0"   },
    // let anchor_list_processed = anchor_list_sorted.map((an, i) => {
    //   let i_mod = i % c_anchors_per_matrix;
    //   const anchor_pos = anchor_pos_seed[i_mod];
    //   const new_an = Object.assign(an, anchor_pos);
    //   new_an.label = an.anchor_id;
    //   return new_an;
    // });
    // return anchor_list_processed;
    //
    //
  }; // \const sort_anchor_list = function(anchors) {}

  const update_api_anchors = () => {
    // set_anchor_loading(true);
    axios
      .get(url_anchors, {}, { timeout: 3000 })
      .then((data) => {
        const anchor_list = data.data.anchors || [];
        const anchor_list_no_id = filter_anchor_list_no_id(anchor_list);
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
        const anchor_list_sorted_aid = anchor_list_no_id.sort((a, b) => a.anchor_id > b.anchor_id);
        set_anchor_table(anchor_list_sorted_aid);
        set_anchor_loading(false);
        //
        const anchor_list_sorted = sort_anchor_list(anchor_list_no_id);
        set_anchor_token_table(anchor_list_sorted);
      })
      .catch((err) => {
        // set_anchor_table(null);
        set_anchor_loading(false);
        set_anchor_token_table([]);
      });
  };

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now
  // "returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC."
  const [interval_time, set_interval_time] = useState(Date.now());
  useEffect(() => {
    //
    update_api_anchors();
    //
    const interval = setInterval(() => {
      set_interval_time(Date.now());
      update_api_anchors();
    }, 1000);
    //
    // to run a func at unmount, return a func here:
    return () => {
      clearInterval(interval);
    };
    //
    // eslint-disable-next-line
  }, []);

  const f_add_anchor_pos_seed = function (an_list) {
    //
    // // { label: "1a01", anr: 1, label_pos: "top", width: "300", height: "200", posx: "0",   posy: "0"   },
    // let anchor_list_processed = an_list.map((an, i) => {
    //   let i_mod = i % c_anchors_per_matrix;
    //   const anchor_pos = anchor_pos_seed[i_mod];
    //   const new_anchor_obj = Object.assign(an, anchor_pos);
    //   new_anchor_obj.label = an.anchor_id;
    //   return new_anchor_obj;
    // });
    //
    const anchors = Array.isArray(an_list) ? an_list : [];
    //
    v_matrix_cols = Math.round(an_list.length / 2);
    //
    let anchor_list_processed = [];
    //
    for (let anr = 1; anr <= v_matrix_cols * v_matrix_rows; anr = anr + 1) {
      //
      const a_i = anr - 1;
      // const anchor_pos = anchor_pos_seed[a_i] || {};
      // const anchor_obj = anchors[a_i] || {};
      // const new_anchor_obj = Object.assign(anchor_obj, anchor_pos);
      // new_anchor_obj.label = anchor_obj.anchor_id || "";
      //
      let anchor_obj = anchors[a_i] || {};
      anchor_obj.anr = anr;
      //
      if (anr <= v_matrix_cols) {
        anchor_obj.label_pos = "top";
        anchor_obj.posy = 0;
      } else {
        // anchor_obj.label_pos = 'bottom';
        anchor_obj.label_pos = "top";
        anchor_obj.posy = c_map_height;
      }
      //
      const rel_posx = (anr - 1) % v_matrix_cols; // relative X position
      anchor_obj.posx = rel_posx * c_map_width;
      //
      const anchor_pos = pos_list.find((obj) => obj.aid === anchor_obj.anchor_id);
      if (anchor_pos) {
        anchor_obj.posx = anchor_pos.x;
        anchor_obj.posy = anchor_pos.y;
      }
      //
      // anchor_obj.label = anchor_obj.anchor_id || '';
      anchor_obj.label = anchor_obj.display_name || anchor_obj.anchor_id || "";
      //
      anchor_list_processed.push(anchor_obj);
    }
    //
    return anchor_list_processed;
    //
  };
  // const anchor_list = anchor_table;
  const anchors_seed_list = f_add_anchor_pos_seed(anchor_token_table);

  const c_factor = 0.9; // 1 // 0.6
  const tmap_w = Math.round(c_map_width * c_factor);
  const tmap_h = Math.round(c_map_height * c_factor);

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          {/* <Map data={map_floor1}></Map> */}
          {/* <img className="mr-3" src={'assets/layout/images/THT-Floorplan-27-05-2019-bw-small.png'} alt="tht-floorplan" /> */}
          {/* <img className="mr-3" src="/assets/layout/images/THT-Floorplan-27-05-2019-bw-small.png" alt="tht-floorplan" /> */}
          {/* <img className="mr-3" src={floorplan} alt="tht-floorplan" /> */}

          {/* JDG idea from: https://stackoverflow.com/questions/43806515/position-svg-elements-over-an-image */}
          <div className="img-overlay-wrap">
            {/* <img src="https://via.placeholder.com/400" /> */}
            {/* <img className="mr-3" src={floorplan} alt="tht-floorplan" /> */}
            <img className="mr-3" src={floorplan} alt="odense-floorplan" />
            {/* <svg viewBox="0 0 200 200">
              <circle cx="75" cy="75" r="50" fill="rebeccapurple" />
            </svg> */}
            <svg width="100%" viewBox={`0 0 ${floorplan_width} ${floorplan_height}`}>
              {/* <rect x="0" y="0" width={floorplan_width} height={floorplan_height} fill="transparent" stroke="red" strokeWidth="2" /> */}

              {anchors_seed_list.map((a_iter) => {
                const token_icons = a_iter.presence_tlist
                  ? a_iter.presence_tlist.map((token_id, index) => {
                      return { token_id: token_id, seq_nr: index };
                    })
                  : [];
                // const token_icons = [] ;
                return <TokenMap key={a_iter.anr} width={tmap_w} height={tmap_h} posx={a_iter.posx} posy={a_iter.posy} label_pos={a_iter.label_pos} label={a_iter.label} token_icons={token_icons} />;
              })}
            </svg>
          </div>
        </div>
      </div>
      {/* <div className="col-4">
        <div className="card">
          <DataTable
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            // header={anchor_table_header}
            value={anchor_table}
            dataKey="_id"
            loading={anchor_loading}
            emptyMessage="No items found."
            // globalFilter={anchor_filter}
            scrollable
            scrollHeight="200px"
            //
            // sortField={sortField} sortOrder={sortOrder} onSort={onSort}
            //
            // sortMode="multiple"
            // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
            //
            // paginator rows={24}
            rowHover
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            size="small"
            columnResizeMode="fit"
            // autoLayout="true"
            resizableColumns
            //
          >
            <Column field="anchor_id" header="Anchor" body={body_template} style={{ width: '6rem' }}></Column>
            <Column field="presence_tlist" header="Tokens" body={tlist_template}></Column>
          </DataTable>
        </div>
      </div> */}
    </div>
  );
}; // \export const OverviewPage = () => {

//-EOF
