//= pages/MQTT-pub.js

// eslint-disable-next-line

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable/
import { Column } from "primereact/column"; // https://www.primefaces.org/primereact/column/
import { Toast } from "primereact/toast";
import { Button } from "primereact/button"; // https://www.primefaces.org/primereact/button/
// import { FileUpload } from 'primereact/fileupload';
import { Rating } from "primereact/rating";
// import { Toolbar } from 'primereact/toolbar';
import { InputText } from "primereact/inputtext"; // https://www.primefaces.org/primereact/inputtext/
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";

import axios from "axios"; // npm i axios --save

import { f_get_substring_length } from "../lib/functions";

export const MqttPubPage_OLD = () => {
  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  const url_mqttpub = api_url + "/api/mqttpub";

  const [mqtt_table, set_mqtt_table] = useState(null);
  const [mqtt_filter, set_mqtt_filter] = useState("");
  const [mqtt_loading, set_mqtt_loading] = useState(true);
  const [mqtt_show_id, set_mqtt_show_id] = useState(false);
  const [mqtt_checkbox_show_id, set_mqtt_checkbox_show_id] = useState([]);

  useEffect(() => {
    //
    axios
      .get(url_mqttpub, {}, { timeout: 3000 })
      .then((data) => {
        set_mqtt_table(data.data.mqttpub);
        set_mqtt_loading(false);
      })
      .catch((error) => {
        set_mqtt_table(null);
        set_mqtt_loading(false);
      });
    //
    // eslint-disable-next-line
  }, []);

  const click_show_api_mqtt = () => {
    window.open(url_mqttpub);
  };
  const click_refresh_api_mqtt = () => {
    axios
      .get(url_mqttpub, {}, { timeout: 3000 })
      .then((data) => {
        set_mqtt_table(data.data.mqttpub);
        set_mqtt_loading(false);
      })
      .catch((error) => {
        set_mqtt_table(null);
        set_mqtt_loading(false);
      });
  };
  const mqtt_on_checkbox_showid_change = (e) => {
    let selectedValue = [...mqtt_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_mqtt_checkbox_show_id(selectedValue);
    set_mqtt_show_id(selectedValue.indexOf("true") !== -1);
  };
  //
  const mqtt_table_header = (
    <div className="table-header">
      <h5>MQTT</h5>
      <div className="field-checkbox">
        <Checkbox inputId="checkShowIDs_mqtt" name="option" value="true" checked={mqtt_checkbox_show_id.indexOf("true") !== -1} onChange={mqtt_on_checkbox_showid_change} />
        <label htmlFor="checkShowIDs_mqtt">show IDs</label>
      </div>
      <Button label="/api/mqttpub" onClick={click_show_api_mqtt} className="p-button-raised p-button-warning mr-2 mb-2" />
      <Button label="refresh" onClick={click_refresh_api_mqtt} className="p-button-raised p-button-warning mr-2 mb-2" />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={mqtt_filter} onChange={(e) => set_mqtt_filter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  // const [sortField, setSortField] = useState('mqtt_id');
  // const [sortOrder, setSortOrder] = useState(1);
  // const onSort = (e) => {
  //   setSortField(e.sortField);
  //   setSortOrder(e.sortOrder);
  // }

  // const [multiSortMeta, setMultiSortMeta] = useState(
  //   [
  //     {field: '_uplink_tech',  order: -1},
  //     {field: 'mqtt_id',     order: 1}
  //   ]
  // );

  // const mqtt_mongoose_schema = new mongoose.Schema({
  //   name:     { type: String, required: true},
  //   host:     { type: String, required: true},
  //   protocol: { type: String, required: true},
  //   port:     { type: Number, required: true},
  //   topic:    { type: String, required: true},
  //   username: { type: String, required: true},
  //   password: { type: String, required: true},
  // },

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          {/* https://www.primefaces.org/primereact/datatable/ */}
          <DataTable
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            header={mqtt_table_header}
            value={mqtt_table}
            dataKey="_id"
            loading={mqtt_loading}
            emptyMessage="No items found."
            globalFilter={mqtt_filter}
            // scrollable scrollHeight="600px"
            //
            // sortField={sortField} sortOrder={sortOrder} onSort={onSort}
            //
            // sortMode="multiple"
            // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
            //
            paginator
            rows={24}
            rowHover
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            columnResizeMode="fit"
            autoLayout="true">
            {mqtt_show_id && <Column field="_id" header="ID" sortable body={body_template}></Column>}
            <Column field="name" header="Name" sortable body={body_template}></Column>
            <Column field="host" header="Hostname" sortable body={body_template}></Column>
            <Column field="topic" header="Topic" sortable body={body_template}></Column>
            <Column field="username" header="Username" sortable body={body_template}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

// - - - - - - = = = - - - - - -
// export Component
export const MqttPubPage = () => {
  //

  let emptyItem = {
    id: null,
    name: "",
  };

  const enum_fields = [
    {
      name: "category",
      label: "Category",
      values: ["Accessories", "Clothing", "Electronics", "Fitness"],
    },
  ];
  const category_enum = enum_fields.find((obj) => obj.name === "category");

  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  const url_mqttpub = api_url + "/api/mqttpub";

  const [item, setItem] = useState(emptyItem);
  const [items, setItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);

  const [itemShowDialog, setItemShowDialog] = useState(false);
  const [deleteSingleItemShowDialog, setDeleteSingleItemShowDialog] = useState(false);
  const [deleteManyItemsShowDialog, setDeleteManyItemsShowDialog] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  const toast = useRef(null);
  const refDataTable = useRef(null);

  const [mqtt_loading, set_mqtt_loading] = useState(true);
  const [mqtt_show_id, set_mqtt_show_id] = useState(false);
  const [mqtt_checkbox_show_id, set_mqtt_checkbox_show_id] = useState([]);

  // const regex_ascii = /^[\x00-\x7f]*$/; // reference: https://en.wikipedia.org/wiki/ASCII
  // const regex_ascii = /^[\x20-\x7e]*$/; // only printable chars
  // const regex_ascii = /^[a-zA-Z0-9\ \.,\-_]*$/; // disallow formatting chars like []{}()<>*&^%$#@!=+\/|`?'~"
  const re_ASCII = new RegExp("^[a-zA-Z0-9 -.,_!?&()%@=+/]*$");

  // - - - - - - = = = - - - - - -
  // API logic:

  const API_getItems = function (props) {
    const func_name = "API_getItems";
    //
    // // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise
    // return new Promise((resolve, reject) => {
    //   let my_data = [];
    //   //
    //   // from original Crud.js, to be replaced with my new API-call:
    //   const itemService = new ProductService();
    //   itemService.getProducts().then((data) => (my_data = data));
    //   //
    //   resolve(my_data);
    // });
    //
    // const itemService = new ProductService();
    // return itemService.getProducts(); // jdg-note: just return promise, let called resolve it.
    //
    // return new Promise((resolve, reject) => {
    //   resolve([]);
    // });
    //
    // axios
    //   .get(url_mqttpub, {}, { timeout: 3000 })
    //   .then((data) => {
    //     set_mqtt_table(data.data.mqttpub);
    //     set_mqtt_loading(false);
    //   })
    //   .catch((error) => {
    //     set_mqtt_table(null);
    //     set_mqtt_loading(false);
    //   });
    //
    return axios.get(url_mqttpub, {}, { timeout: 3000 });
    //
  };

  const API_saveEditItem = function ({ items, item }) {
    const index = findIndexById(item.id);
    items[index] = item; // save
    return items;
  };

  const API_saveNewItem = function ({ items, item }) {
    item.id = createId();
    item.image = "item-placeholder.svg";
    items.push(item); // save
    return items;
  };

  const API_deleteSingleItem = function ({ items, item }) {
    let _items = items.filter((val) => val.id !== item.id);
    setItems(_items);
  };

  const API_deleteManyItems = function ({ items, selectedItems }) {
    let _items = items.filter((val) => !selectedItems.includes(val));
    setItems(_items);
  };

  // - - - - - - = = = - - - - - -
  // local logic

  useEffect(() => {
    // const data = await API_getItems();
    API_getItems().then((data) => setItems(data.data.mqttpub));
    set_mqtt_loading(false);
    // eslint-disable-next-line
  }, []);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  };

  const openNew = () => {
    setItem(emptyItem);
    setSubmitted(false);
    setItemShowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setItemShowDialog(false);
  };

  const hideDeleteSingleItemDialog = () => {
    setDeleteSingleItemShowDialog(false);
  };

  const hideDeleteManyItemsDialog = () => {
    setDeleteManyItemsShowDialog(false);
  };

  const saveItem = () => {
    setSubmitted(true);
    //
    // const _items = API_saveItem();
    //
    let _items = [...items];
    let _item = { ...item };
    if (item.name.trim()) {
      if (item.id) {
        // const index = findIndexById(item.id);
        // _items[index] = _item;
        //
        _items = API_saveEditItem({ items: _items, item: _item });
        //
        toast.current.show({ severity: "success", summary: "Successful", detail: "Item Updated", life: 3000 });
      } else {
        // _item.id = createId();
        // _item.image = 'item-placeholder.svg';
        // _items.push(_item);
        //
        _items = API_saveNewItem({ items: _items, item: _item });
        //
        toast.current.show({ severity: "success", summary: "Successful", detail: "Item Created", life: 3000 });
      }
    }
    //
    setItems(_items);
    setItemShowDialog(false);
    setItem(emptyItem);
  };

  const editItem = (item) => {
    setItem({ ...item });
    setItemShowDialog(true);
  };

  const confirmSingleDeleteItem = (item) => {
    setItem(item);
    setDeleteSingleItemShowDialog(true);
  };

  const deleteSingleItem = () => {
    //
    // let _items = items.filter((val) => val.id !== item.id);
    // setItems(_items);
    //
    API_deleteSingleItem({ items: items, item: item });
    //
    setDeleteSingleItemShowDialog(false);
    setItem(emptyItem);
    toast.current.show({ severity: "success", summary: "Successful", detail: "Item Deleted", life: 3000 });
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  };

  // const exportCSV = () => {
  //   refDataTable.current.exportCSV();
  // };

  const confirmDeleteSelected = () => {
    setDeleteManyItemsShowDialog(true);
  };

  const deleteSelectedItems = () => {
    //
    // let _items = items.filter((val) => !selectedItems.includes(val));
    // setItems(_items);
    //
    API_deleteManyItems({ items: items, selectedItems: selectedItems });
    //
    setDeleteManyItemsShowDialog(false);
    setSelectedItems(null);
    toast.current.show({ severity: "success", summary: "Successful", detail: "Items Deleted", life: 3000 });
  };

  const mqtt_on_checkbox_showid_change = (e) => {
    let selectedValue = [...mqtt_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_mqtt_checkbox_show_id(selectedValue);
    set_mqtt_show_id(selectedValue.indexOf("true") !== -1);
  };

  const onCategoryChange = (e) => {
    let _item = { ...item };
    _item["category"] = e.value;
    setItem(_item);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _item = { ...item };
    _item[`${name}`] = val;

    setItem(_item);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _item = { ...item };
    _item[`${name}`] = val;

    setItem(_item);
  };

  // const leftToolbarTemplate = () => {
  //   return (
  //     <React.Fragment>
  //       <div className="my-2">
  //         <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
  //         <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedItems || !selectedItems.length} />
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  // const rightToolbarTemplate = () => {
  //   return (
  //     <React.Fragment>
  //       <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
  //       <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
  //     </React.Fragment>
  //   );
  // };

  // - - - - - - = = = - - - - - -
  // Templates:

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  const multiBodyTemplate = (data, props) => {
    const my_header = props.header;
    const my_field = props.field;
    const my_data = data[my_field];
    let my_data_formatted = my_data;
    switch (my_field) {
      case "topic":
        const max_length = 48;
        my_data_formatted = f_get_substring_length({ str: my_data, length: max_length });
        break;
      case "connected":
        my_data_formatted = my_data ? "true" : "false";
        break;
      default:
        break;
    }
    return (
      <>
        <span className="p-column-title">{my_header}</span>
        {my_data_formatted}
      </>
    );
  };

  // const topic_template = (rowData) => {
  //   const max_length = 48;
  //   const new_topic_brief = f_get_substring_length({ str: rowData.topic, length: max_length });
  //   return (
  //     <>
  //       <span className="p-column-title">Topic</span>
  //       {new_topic_brief}
  //     </>
  //   );
  // };

  // const codeBodyTemplate = (rowData) => {
  //   return (
  //     <>
  //       <span className="p-column-title">Code</span>
  //       {rowData.code}
  //     </>
  //   );
  // };

  // const nameBodyTemplate = (rowData) => {
  //   return (
  //     <>
  //       <span className="p-column-title">Name</span>
  //       {rowData.name}
  //     </>
  //   );
  // };

  // const imageBodyTemplate = (rowData) => {
  //   return (
  //     <>
  //       <span className="p-column-title">Image</span>
  //       <img src={`assets/demo/images/item/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
  //     </>
  //   );
  // };

  // const priceBodyTemplate = (rowData) => {
  //   return (
  //     <>
  //       <span className="p-column-title">Price</span>
  //       {formatCurrency(rowData.price)}
  //     </>
  //   );
  // };

  // const categoryBodyTemplate = (rowData) => {
  //   return (
  //     <>
  //       <span className="p-column-title">Category</span>
  //       {rowData.category}
  //     </>
  //   );
  // };

  // const ratingBodyTemplate = (rowData) => {
  //   return (
  //     <>
  //       <span className="p-column-title">Reviews</span>
  //       <Rating value={rowData.rating} readonly cancel={false} />
  //     </>
  //   );
  // };

  // const statusBodyTemplate = (rowData) => {
  //   return (
  //     <>
  //       <span className="p-column-title">Status</span>
  //       <span className={`item-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
  //     </>
  //   );
  // };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editItem(rowData)} tooltip="Edit" tooltipOptions={{ position: "top" }} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmSingleDeleteItem(rowData)} tooltip="Delete" tooltipOptions={{ position: "top" }} />
      </div>
    );
  };

  const tableHeader = (
    <div
      className="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
      // className="table-header"
    >
      <h5 className="m-0">MQTT-Publish</h5>
      <a href={url_mqttpub} target="_blank" rel="noreferrer">
        (API)
      </a>
      {/* <div className="field-checkbox">
        <Checkbox inputId="checkShowIDs_mqtt" name="option" value="true" checked={mqtt_checkbox_show_id.indexOf('true') !== -1} onChange={mqtt_on_checkbox_showid_change} />
        <label htmlFor="checkShowIDs_mqtt">show IDs</label>
      </div> */}
      {/* <Button label="/api/mqttpub" onClick={click_show_api_mqtt} className="p-button-raised p-button-warning mr-2 mb-2" />
      <Button label="refresh" onClick={click_refresh_api_mqtt} className="p-button-raised p-button-warning mr-2 mb-2" /> */}
      <div className="my-2">
        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
        <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedItems || !selectedItems.length} />
      </div>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        {/* https://www.primefaces.org/primereact/inputtext/ */}
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
      </span>
      {/* <Button
          icon="pi pi-icon p-c pi-times-circle"
          className="p-button-rounded"
          onClick={() => {
            setGlobalFilter(null);
          }}
          disabled={!globalFilter || !globalFilter.length}
        /> */}
    </div>
  );

  const itemDialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveItem} />
    </>
  );
  const deleteSingleItemDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSingleItemDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSingleItem} />
    </>
  );
  const deleteManyItemsDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteManyItemsDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItems} />
    </>
  );

  // - - - - - - = = = - - - - - -
  // Render logic:

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          {/* - - - - - - = = = - - - - - - */}
          <Toast ref={toast} />

          {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

          {/* - - - - - - = = = - - - - - - */}
          <DataTable
            ref={refDataTable}
            value={items}
            selection={selectedItems}
            onSelectionChange={(e) => setSelectedItems(e.value)}
            dataKey="id"
            // dataKey="_id"
            header={tableHeader}
            loading={mqtt_loading}
            paginator
            rows={10}
            rowHover
            rowsPerPageOptions={[5, 10, 25]}
            // className="datatable-responsive"
            // className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            className="datatable-responsive p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
            globalFilter={globalFilter}
            emptyMessage="No items found."
            // scrollable scrollHeight="600px"
            // sortField={sortField} sortOrder={sortOrder} onSort={onSort}
            // sortMode="multiple"
            // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            columnResizeMode="fit"
            autoLayout="true">
            {mqtt_show_id && <Column field="_id" header="ID" sortable body={multiBodyTemplate}></Column>}
            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
            <Column field="name" header="Name" sortable body={multiBodyTemplate}></Column>
            <Column field="host" header="Hostname" sortable body={multiBodyTemplate}></Column>
            <Column field="topic" header="Topic" sortable body={multiBodyTemplate}></Column>
            <Column field="username" header="Username" sortable body={multiBodyTemplate}></Column>
            <Column field="connected" header="Connected" sortable body={multiBodyTemplate}></Column>
            <Column field="clientid" header="ClientID" sortable body={multiBodyTemplate}></Column>
            {/* <Column field="code" header="Code" sortable body={codeBodyTemplate}></Column>
            <Column field="name" header="Name" sortable body={nameBodyTemplate}></Column>
            <Column header="Image" body={imageBodyTemplate}></Column>
            <Column field="price" header="Price" body={priceBodyTemplate} sortable></Column>
            <Column field="category" header="Category" sortable body={categoryBodyTemplate}></Column>
            <Column field="rating" header="Reviews" body={ratingBodyTemplate} sortable></Column>
            <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} sortable></Column> */}
            <Column header="Actions" body={actionBodyTemplate}></Column>
          </DataTable>

          {/* - - - - - - = = = - - - - - - */}
          <Dialog visible={itemShowDialog} style={{ width: "450px" }} header="MQTT-Pub details" modal className="p-fluid" footer={itemDialogFooter} onHide={hideDialog}>
            {/* - - - - - - = = = - - - - - - */}
            <div className="field">
              <label htmlFor="name">Name</label>
              <InputText id="name" value={item.name} onChange={(e) => onInputChange(e, "name")} required autoFocus className={classNames({ "p-invalid": submitted && !item.name })} />
              {submitted && !item.name && <small className="p-invalid">Name is required.</small>}
            </div>
            {/* - - - - - - = = = - - - - - - */}
            <div className="field">
              <label htmlFor="description">Description</label>
              <InputTextarea id="description" value={item.description} onChange={(e) => onInputChange(e, "description")} required rows={3} cols={20} />
            </div>
            {/* - - - - - - = = = - - - - - - */}
            <div className="field">
              <label className="mb-3">{category_enum.label}</label>
              <div className="formgrid grid">
                {category_enum.values &&
                  category_enum.values.map((cat, index) => {
                    return (
                      <div className="field-radiobutton col-6">
                        <RadioButton inputId={`${category_enum.name}_${index}`} name={category_enum.name} value={cat} onChange={onCategoryChange} checked={item.category === cat} />
                        <label htmlFor={`${category_enum.name}_${index}`}>{cat}</label>
                      </div>
                    );
                  })}
              </div>
              {/* - - - - - - = = = - - - - - - */}
            </div>
            <div className="formgrid grid">
              {/* - - - - - - = = = - - - - - - */}
              <div className="field col">
                <label htmlFor="price">Price</label>
                <InputNumber id="price" value={item.price} onValueChange={(e) => onInputNumberChange(e, "price")} mode="currency" currency="USD" locale="en-US" />
              </div>
              {/* - - - - - - = = = - - - - - - */}
              <div className="field col">
                <label htmlFor="quantity">Quantity</label>
                <InputNumber id="quantity" value={item.quantity} onValueChange={(e) => onInputNumberChange(e, "quantity")} integeronly />
              </div>
            </div>
          </Dialog>

          {/* - - - - - - = = = - - - - - - */}
          <Dialog visible={deleteSingleItemShowDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemDialogFooter} onHide={hideDeleteSingleItemDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
              {item && (
                <span>
                  Are you sure you want to delete <b>{item.name}</b>?
                </span>
              )}
            </div>
          </Dialog>

          {/* - - - - - - = = = - - - - - - */}
          <Dialog visible={deleteManyItemsShowDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteManyItemsDialogFooter} onHide={hideDeleteManyItemsDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
              {item && <span>Are you sure you want to delete the selected items?</span>}
            </div>
          </Dialog>
          {/* - - - - - - = = = - - - - - - */}
        </div>
      </div>
    </div>
    // - - - - - - = = = - - - - - -
  ); // \return ()
  // - - - - - - = = = - - - - - -
}; // \export const MqttPubPage = () => {}

//-eof
