//= ./src/components/TableColors.jsx

import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
//
import { TableTemplate } from "../components/common/TableTemplate";
import { f_nr_to_padzero_string, gen_colors } from "../lib/functions";

// - - - - - - = = = - - - - - -
const mod_name = "pages_TableColors";
const func_name = `${mod_name}:anonymous`; // <== default, needs to be locally overwritten

// Icons =>        https://www.primefaces.org/primereact/icons/
// CSS =>          https://www.primefaces.org/primeflex/
// Componenets =>  https://www.primefaces.org/primereact/calendar/

const api_url = window._env_.API_BASE_URL;
const url_api_v3_anchorgroup = api_url + "/api/v3/anchorgroup";

export const TableColors = (props) => {
  //
  //   api_url: "https://cloud52.sostark.nl/code/75114096/dashboard/api/v3/anchorgroup",

  const model = {
    api_url: url_api_v3_anchorgroup,
    item_name_singular: "Table-Color",
    item_name_plural: "Table-Colors",
    table_title: "Manage Table-Colors",
    dialog_title: "Table-Color Details",
    dialog_width: "450px",
    msg_empty: "No Table-Colors found.",
    msg_confirm_dialog_header: "Confirm",
    msg_are_you_sure_delete_single_item: "Are you sure you want to delete",
    msg_are_you_sure_delete_multi_item: "Are you sure you want to delete the selected Table-Colors?",
    fields: [
      {
        field: "id",
        label: "ID",
        dialog_type: "id",
        focus: false,
        table_type: "string",
        required: false,
        sortable: true,
      },
      {
        field: "tpid",
        label: "TPID",
        dialog_type: "tpid",
        focus: false,
        table_type: "tpid",
        required: false,
        sortable: true,
      },
      {
        field: "tpid2",
        label: "TPID2",
        dialog_type: "tpid",
        focus: false,
        table_type: "tpid",
        required: false,
        sortable: true,
      },
      {
        field: "sort_nr",
        label: "sort_nr",
        dialog_type: "string",
        focus: false,
        table_type: "string",
        required: false,
        sortable: true,
      },
    ],
    table_fields_order: ["tpid", "tpid2", "sort_nr"],
    dialog_fields_order: ["tpid"],
    main_field: "tpid",
    initial_sort_field: "sort_nr",
    data_key: "id",
    rows: 100,
    rows_per_page_options: [5, 10, 25, 50, 100],
    operations: ["new", "edit", "delete"], // all options: 'new', 'view', 'edit', 'duplicate', 'delete' // NVEDD
    show_showid_button: true,
    show_openapi_button: true,
    show_refresh_button: true,
    auto_refresh_secs: 0,
    auto_refresh_options: [0, 1, 3, 5, 10, 30, 60],
    new_item_template: {
      tpid: null,
    },
    image_path: "assets/demo/images/item",
    //
    fa_model_get_items: undefined,
    fa_model_set_item: undefined,
    //
  };

  // - - - - - - = = = - - - - - -
  // API logic:

  const fa_model_get_items = async function () {
    //
    // let steps = ["4", "6", "8", "a", "c", "e"];
    let steps = ["4", "7", "a", "d"];
    //
    let colors_list = [];
    let index = 0;
    for (let step1 of steps) {
      for (let step2 of steps) {
        for (let step3 of steps) {
          // const new_color = `${step1}${step2}${step3}`;
          const new_color = `${step1}${step1}${step2}${step2}${step3}${step3}`;
          index += 1;
          colors_list.push({
            color: new_color,
            index: index,
          });
        }
      }
    }
    //
    // let my_gen_colors = gen_colors();
    // let my_gen_colors_keys = Object.keys(my_gen_colors);
    let get_items = colors_list.map((col) => {
      // const sort_nr = parseInt(col, 16) || 1;
      const sort_nr = col.index - 1;
      return {
        id: sort_nr,
        tpid: `C-${col.color}`,
        tpid2: `A-${f_nr_to_padzero_string(sort_nr.toString(16), 4)}`,
        sort_nr: sort_nr,
      };
    });
    f_set_items(get_items); // this will cause a re-render
    //
  };

  const fa_model_set_item = async function ({ action, item }) {
    const func_name = "fa_model_set_item";
    //
    let updated_item = undefined;
    // console.log(`${func_name} action=${action}`);
    //
    return new Promise(async (resolve, reject) => {
      try {
        switch (action) {
          case "new":
            break;
          case "update":
            break;
          case "deleteOne":
            break;
          case "deleteMany":
            break;
          default:
            throw new Error("unknown action");
        }
        resolve(updated_item);
      } catch (err) {
        reject(err);
      }
    });
    //
  };

  model.fa_model_get_items = fa_model_get_items;
  model.fa_model_set_item = fa_model_set_item;

  // - - - - - - = = = - - - - - -
  // LOCAL STATE:
  const [items, f_set_items] = useState(null);

  // - - - - - - = = = - - - - - -
  // Render logic:
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <TableTemplate parent_items={items} model={model} />
        </div>
      </div>
    </div>
    // - - - - - - = = = - - - - - -
  ); // \return ()
  // - - - - - - = = = - - - - - -
};

//-eof
